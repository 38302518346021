.header {
    box-shadow: rgba(54, 43, 59, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 2.5rem;
    position: absolute;
    top: 0;
}

.headerLogo {
    width: 90px;
}