.heartBeat, .heartBeat svg {
    display: inline-block;
    margin-left: 3px;
    animation: beat-it .7s linear infinite alternate;
  }

  .heartBeat svg {
    margin-left: 0;
  }
  
@keyframes beat-it {
    to {
        fill: red;
        transform: scale(1.3);
    }
}

footer {
    padding-bottom: 2rem;
}

footer p {
    word-spacing: 3px;
}
