.gradient {
    background: linear-gradient(to bottom right,
            #EA9863,
            #B00AFC);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color:
        transparent;
}

.headline h1 {
    font-size: 5rem;
    line-height: 1.3;
}

.title {
    display: block;
    font-weight: lighter;
    font-size: medium;
    text-align: start;
    margin-left: 15rem;
}

@media (max-width: 430px) {
    .headline .gradient {
        display: block;
        font-size: 5.5rem;
    }

    .title {
        text-align: center;
        margin-left: 0;
        margin-top: 1.5rem;
    }
}