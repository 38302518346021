:root {
    --justify: end;
    --gap: 1.5rem;
    --radius: 5px;
}

.description {
    /* background: rgb(13,14,14); */
    background: linear-gradient(11deg, rgba(13,14,14,1) 25%, rgba(45,45,51,0.9555480072463768) 76%);
    border-radius: var(--radius);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding: 1.5rem;
}

.flex {
    display: flex;
    justify-content: end;
    gap: var(--gap);
}

.projectCard {
	align-items:center;
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-template-rows: repeat(5, 1fr);
    margin-bottom: 4.5rem;
    min-height: 491px;
}

.projectDetails {
	grid-column: 4 / -1;
	grid-row: 2 /span 3;
	text-align: end;
	border-radius: 3px;
	color: whitesmoke;
}

.projectImg {
    background-color: #55115a;
	grid-column: 1 / 8;
	grid-row: 1 / -1;
    box-shadow: rgb(71, 44, 77) 0px 20px 30px -10px;
    z-index: -1;
}

.projectImg img {
    border-radius: 3px;
	width: 100%;
	height: 375px;
	object-fit: cover;
    opacity: 0.2;
}

.projectTitle {
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 1rem;
}

.techStack {
    color: var(--color-light);
    font-family: var(--code-font);
    font-size: 0.9rem;
    margin: 1rem 0;
}

.techStack li:hover {
    color: var(--color-highlight-variant);
}

@media (max-width: 768px) {

    .description {
        background: none;
        box-shadow: none;
        opacity: 0.7;
        padding: 1.5rem 0;
    }

    .projectDetails {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        padding: 2.5rem;
        text-align: left;
        min-width: 350px;
    }

    .projectImg {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        background-color: #1f0e20;
        box-shadow: rgb(30, 24, 32) 0px 10px 10px 0px;
    }

    .projectImg img {
        height: 450px;
        opacity: 0.03;
    }

    .projectTitle {
        color: #e08580;
    }

    .flex {
        justify-content: flex-start;
    }
}

@media (max-width: 526px) {
    .techStack {
        flex-wrap: wrap;
    }
}