@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: rgb(10, 10, 10);
    --color-bg-variant: #171822;
    --color-primary: #B00AFC;
    --color-highlight: #e055e4;
    --color-highlight-variant: #d78949;
    --color-primary-variant:rgba(234, 152, 99, 0.4);
    --color-white: rgb(211, 210, 210);
    --bright: #fff;
    --color-light: rgba(255,255,255,0.6);
    --color-gradient: linear-gradient(
        to bottom right, 
        #EA9863,
        #B00AFC
      );

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --main-font: 'Poppins', sans-serif;
    --code-font: "Oxygen mono", monospace;
    --text-emphasis: linear-gradient(
        to bottom right, 
        #EA9863,
        #B00AFC
      );

    --transition: all 0.3s ease;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
}

/* ===== GENERAL STYLES ===== */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, 
h2, 
h3, 
h4, 
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-light);
    transition: var(--transition);
}

a:hover {
    color: var(--color-primary)
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.emphasis {
    background: var(--text-emphasis);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color:
        transparent;
}