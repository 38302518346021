.content {
    max-width: 475px;
    margin: 0 auto;
    text-align: left;
    padding: 10rem 0;
}

span.intro {
    display: block;
    font-family: var(--code-font);
    font-size: 1.2rem;
    line-height: 1;
    color: var(--color-highlight);
}

.content h1 {
    font-size: 4rem;
}

p {
    color: var(--color-light)
}

.emphasis {
    background: var(--text-emphasis);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color:
        transparent;
}

@media (max-width: 520px) {
    .content {
        padding: 0;
        text-align: justify;
    }

    .content h1 {
        font-size: 3rem;
    }
    
}