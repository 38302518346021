.projectsContainer {
    padding: 2rem 6rem;
    margin: 0 auto;
    max-width: 73%;
}

/* TODO fix intial break point for small screens to break sooner */
@media (max-width: 768px) {
    .projectsContainer {
        padding: 2rem 1.5rem;
    }
}

@media (max-width: 526px) {
    .projectsContainer {
        padding: 0;
        margin: 0.3rem;
    }
}