.socialLinks {
    display: flex;
    flex-direction: column;
    width: 2.5rem;
    padding: 1rem;
    margin-left: 4.2rem;
    justify-content: space-evenly;
    gap: 1.2rem;
    align-items: center;
    text-align: center;
    position: sticky;
    bottom: 30px;
}

.socialLinks svg {
    margin-top: 0.6rem;
    transition: var(--transition);
}

.socialLinks svg:hover {
    transform: scale(1.5);
}

@media (max-width: 913px) {
    .socialLinks {
        flex-direction: row;
        justify-content: center;
        gap: 4.2rem;
        padding-bottom: 2.5rem;
        position: static;
        margin-left: 0;
        width: unset;
    }
}