.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.contact button {
    --color-highlight: #EA9863;
    font-size: 1.2rem;
    margin-top: 4rem;
    min-width: 35%;
}

.contact input, 
.contact textarea {
    border-radius: 5px;
    font-size: 1.2rem;
    min-height: 50px;
    padding: 0.6rem;
    width: 80%;
}

.contact input:focus, 
.contact textarea:focus {
    border: 2px solid var(--color-highlight-variant)
}

.contact label {
    margin-top: 1.5rem;
}

.formContainer {
    padding: 5rem 0;
    max-width: 45%;
    margin: 3rem auto;
    transition: var(--transition);
}

.formContainer h2 {
    margin-bottom: 0.3rem;
}

.formTopper {
    margin-bottom: 2rem;
}

.thanks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.thanks button {
    --color-highlight: #EA9863;
    margin-top: 2rem;
    width: 35%;
}

.thanks p {
    color: var(--color-light);
    opacity: 0.8;
}

@media (max-width: 913px) {
    .contact input, 
    .contact textarea {
        width: 100%;
    }

    .formContainer {
        max-width: 100%;
        /* min-width: 450px; */
    }
}