.btn {
    background-color: transparent;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 10px #ea996358;
    color: var(--color-highlight-variant);
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 3px;
    margin: 10px;
    padding: 0.8rem 1rem;
    transition: var(--transition);
}

.btn:hover {
    background-color: var(--color-white);
    border-color: transparent;
    color: var(--color-bg);
    transform: scale(1.05);
}

.btn-primary {
    background-color: var(--color-highlight-variant);
    border: 1px solid var(--color-highlight-variant);
    color: var(--color-white);
}

.btn-secondary {
    background-color: transparent;
    border: 1px solid var(--color-highlight-variant);
    color: var(--color-highlight-variant);
}