.App {
  text-align: center;
  /* position: relative; */
}

.btnContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}

section {
  padding: 0 2rem;
  border: 1px solid transparent;
}

@media (max-width: 526px) {
  section {
    padding: 1rem;
  }

  .btnContainer {
    flex-direction: column;
    padding: 0 1.5rem;
  }

  .btnContainer button {
    font-size: 1.4rem;
    padding: 1.4rem 0;
  }
}